import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminConsoleDataTable from "../AdminConsoleDataTable";
import fetchData from "../../../../axios/fetchData";
import { coilGateways } from "../../../../axios/serverUrl";
import CustomLoader from "../../../../component/Loader/Loader";
import SmartCoilCloneForm from "./SmartCoilCloneForm";
import SmartCoilEditForm from "./SmartCoilEditForm";
import {getSelectedSharedAttributes} from "../../../../axios/deviceApi";
import {getLabelForState} from "../../../../utils/device";

const CoilGateways = ({ coilList }) => {
  const navigator = useNavigate();
  const [coilGatewayTableJson, setCoilGatewayTableJson] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showCloneForm, setShowCloneForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(undefined);
  useEffect(() => {
    if (JSON.parse(localStorage.userDetail)?.authority !== "TENANT_ADMIN") {
      fetchCurrentCustomerCoilGateways();
    } else {
      fetchCoilGateways();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCoilGateways = async () => {
    try {
      setLoading(true);
      const response = await fetchData(
        "GET",
        `${coilGateways}?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
        {
          "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        {}
      );
      if (response?.errorCode === 11) {
        setLoading(false);
        navigator("/login");
        localStorage.clear();
      }
      await setAllData(response?.data);
    } catch (error) {
      console.error("Error fetching coil gateways:", error);
    }
  };

  const fetchCurrentCustomerCoilGateways = async () => {
    try {
      setLoading(true);
      if (coilList?.length > 0) {
        await setAllData(coilList);
      }
    } catch (error) {
      console.error("Error fetching coil gateways:", error);
    }
  };

  async function getSmartCoilState(deviceId) {
    return await getSelectedSharedAttributes(deviceId).then((response) => {
      return response.smartcoil_state;
    });
  }

  const setAllData = async (response) => {
    if (response) {
      const coilGatewayTableJson = await Promise.all(
          response?.map(async (coilGatewayData) => {
            const smartCoilState = await getSmartCoilState(coilGatewayData.id);
            return {
              image: <></>,
              id: coilGatewayData.id,
              name: coilGatewayData.name,
              label: coilGatewayData.label,
              customerId: coilGatewayData.customerId,
              deviceProfileId: coilGatewayData.deviceProfileId,
              deviceProfileName: coilGatewayData.deviceProfileName,
              imageText: coilGatewayData.name,
              column1data: coilGatewayData.label,
              column2data: coilGatewayData.type,
              column3data: getLabelForState(smartCoilState),
            };
          })
      );
      setCoilGatewayTableJson(coilGatewayTableJson);
      setLoading(false);
    } else if (response.errorCode === 11) {
      navigator("/login");
      localStorage.clear();
    }
  };
  // const renderButton = (label, onClick) => (
  //   <button
  //     type="button"
  //     className="btn btn-outline-secondary btn-sm new-user-btn"
  //     onClick={onClick}
  //   >
  //     {label}
  //   </button>
  // );

  function handleClone(row) {
    setShowCloneForm(true);
    setSelectedGateway(row);
  }

  function handleDeviceCloned(createdDevice) {
    setShowCloneForm(false);
    setSelectedGateway(undefined);
    fetchCoilGateways();
  }

  function handleCloneFormClose() {
    setShowCloneForm(false);
    setSelectedGateway(null);
  }

  function handleEdit(row) {
    setShowEditForm(true);
    setSelectedGateway(row);
  }

  function handleDeviceEdited(editedDevice) {
    setShowEditForm(false);
    setSelectedGateway(undefined);
    fetchCoilGateways();
  }

  function handleEditFormClose() {
    setShowEditForm(false);
    setSelectedGateway(null);
  }

  return (
    <div>
      <SmartCoilCloneForm
        show={showCloneForm}
        smartCoilGateway={selectedGateway}
        onClose={handleCloneFormClose}
        onSubmit={handleDeviceCloned}
      />
      <SmartCoilEditForm
          show={showEditForm}
          smartCoilGateway={selectedGateway}
          onClose={handleEditFormClose}
          onSubmit={handleDeviceEdited}
      />
      <AdminConsoleDataTable
        tableHeadings={[
          "Name",
          "Label",
          "Device Profile",
          "SmartCoil State",
        ]}
        defaultSortFieldId="column1"
        addActions={true}
        handleClone={handleClone}
        handleEdit={handleEdit}
        tableJson={coilGatewayTableJson}
      />
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
});
export default connect(mapStateToProps, {})(CoilGateways);
