import fetchData from "./fetchData";
import {customers, customer} from "./serverUrl";

export const getCustomers = () => {
    return fetchData(
        "GET",
        `${customers}?page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
        {
            "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
    );
}

export const getCustomerBuildings = (customerId) => {
    return fetchData(
        "GET",
        `${customer}/${customerId}/assetInfos/?page=${0}&pageSize=${50}&type=Building&sortProperty=name&sortOrder=ASC`,
        {
            "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
    );
}
