import {Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import {createDevice, getSharedAttributes, saveSharedAttributes} from "../../../../axios/deviceApi";

const SmartCoilCloneForm = (props) => {
    const initialState = {
        name: '',
        label: '', // Example value
    };
    const [formData, setFormData] = useState(initialState);
    const [validated, setValidated] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCancel = (e) => {
        setValidated(false);
        setFormData(initialState);
        props.onClose()
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        getSharedAttributes(props.smartCoilGateway.id);

        if (formData.name && formData.label) {
            createDevice({
                ...formData,
                deviceProfileId: props.smartCoilGateway?.deviceProfileId
            }).then(createdDevice => {
                getSharedAttributes(props.smartCoilGateway?.id).then((attributes) => {
                    console.log(createdDevice);
                    saveSharedAttributes(createdDevice.id, attributes).then(() => {
                        props.onSubmit(createdDevice);
                    }).catch((error) => {
                        toast.error(error.message);
                        console.error("Error cloning shared attributes:", error);
                    });
                }).catch((error) => {
                    toast.error(error.message);
                    console.error("Error retrieving shared attributes:", error);
                });
            }).catch((error) => {
                toast.error(error.message);
                console.error("Error cloning device:", error);
            }).finally(() => {
                setValidated(false);
                setFormData(initialState);
            })
        } else {
            setValidated(true);
        }
    };

    return (
        <>
            <ToastContainer />
            <Modal className="modal" show={props.show} onHide={handleCancel}>
                <Modal.Header closeButton><span className="admin-subtitle">Clone device</span></Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated}>
                        <Form.Group controlId="sourceDevice" className="mb-3">
                            <Form.Label>Source device</Form.Label>
                            <Form.Control
                                type="text"
                                name="sourceDeviceName"
                                value={props.smartCoilGateway?.name}
                                readOnly
                                className="mb-3"
                            />
                            <Form.Control
                                type="text"
                                name="sourceDeviceLabel"
                                value={props.smartCoilGateway?.label}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group controlId="New device" className="mb-3">
                            <Form.Label>New device</Form.Label>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Name *"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    isInvalid={validated && !formData.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="label">
                                <Form.Control
                                    type="text"
                                    name="label"
                                    placeholder="Label *"
                                    value={formData.label}
                                    onChange={handleChange}
                                    required
                                    isInvalid={validated && !formData.label}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group controlId="deviceProfile">
                            <Form.Label>Device Profile</Form.Label>
                            <Form.Control
                                type="text"
                                name="deviceProfile"
                                value={props.smartCoilGateway?.deviceProfileName}
                                readOnly
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-danger" onClick={handleCancel}>Cancel</button>
                    <button className="btn btn-outline-success" onClick={handleSubmit}>Clone</button>
                </Modal.Footer>
            </Modal>
        </>
)
}
export default SmartCoilCloneForm;
