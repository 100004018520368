import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import AdminConsoleDataTable from "../AdminConsoleDataTable";
import fetchData from "../../../../axios/fetchData";
import CustomLoader from "../../../../component/Loader/Loader";
import {
  formattedDate,
  formattedDateString,
} from "../../../../axios/formattedDate";
import { buildings } from "../../../../axios/serverUrl";
import BuildingImage from "../../../../assets/images/buildings.png";

const Buildings = ({ buildingList }) => {
  const navigator = useNavigate();
  const [buildingTableJson, setBuildingTableJson] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (JSON.parse(localStorage.userDetail)?.authority !== "TENANT_ADMIN") {
      fetchCurrentCustomerBuildings();
    } else {
      fetchBuildings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBuildings = async () => {
    try {
      setLoading(true);
      const response = await fetchData(
        "GET",
        `${buildings}?type=Building&page=${0}&pageSize=${50}&sortProperty=createdTime&sortOrder=DESC`,
        {
          "X-Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
      );
      if (response?.data) {
        setAllData(response?.data, false);
      } else if (response?.errorCode === 11) {
        navigator("/login");
        localStorage.clear();
      }
    } catch (error) {
      console.error("Error fetching buildings:", error);
    }
  };

  const fetchCurrentCustomerBuildings = async () => {
    try {
      setLoading(true);
      if (buildingList?.length > 0) {
        setAllData(buildingList, true);
      }
    } catch (error) {
      console.error("Error fetching coil gateways:", error);
    }
  };

  const setAllData = (response, isCustomer) => {
    if (isCustomer) {
      var { firstName, lastName } = JSON.parse(
        localStorage.getItem("userDetail")
      );
    }
    if (response) {
      const buildingTableJson = response?.map((buildingData) => ({
        image: (
          <img className="mr-3" src={BuildingImage} alt="Accountprofile" />
        ),
        imageText: buildingData.name,
        column1data: isCustomer
          ? firstName + " " + lastName
          : buildingData.customerTitle,
        column2data: buildingData.customerIsPublic ? "Public" : "Private",
        column3data: (
          <div
            href="#"
            data-toggle="tooltip"
            data-placement="top"
            style={{ cursor: "pointer" }}
            title={formattedDate(buildingData.createdTime)}
          >
            {formattedDateString(buildingData.createdTime)}
          </div>
        ),
      }));
      setBuildingTableJson(buildingTableJson);
      setLoading(false);
    } else if (response.errorCode === 11) {
      navigator("/login");
      localStorage.clear();
    }
  };

  // const renderButton = (label, onClick) => (
  //   <button
  //     type="button"
  //     className="btn btn-outline-secondary btn-sm new-user-btn"
  //     onClick={onClick}
  //   >
  //     {label}
  //   </button>
  // );

  return (
    <div>
      {/* <div className="new-user-btn-wrapper mb-md-0 mb-3 float-md-right">
        {renderButton("Add New Buildings")}
      </div> */}

      {/* <div className="select-wrapper d-block d-md-flex mb-3 mb-md-0">
        <div className="d-flex mb-3 mb-md-0">
          <select className="form-select" aria-label="select example">
            <option value="" selected>
              Bulk Actions
            </option>
            <option value="1">Actions One</option>
            <option value="2">Actions Two</option>
            <option value="3">Actions Three</option>
          </select>
          {renderButton("Apply")}
        </div>

        <div className="d-flex">
          <select className="form-select" aria-label="select example">
            <option value="" selected>
              Change role to
            </option>
            <option value="1">role One</option>
            <option value="2">role Two</option>
            <option value="3">role Three</option>
          </select>
          {renderButton("Change")}
        </div>
      </div> */}

      {/* Table content  */}

      <AdminConsoleDataTable
        tableHeadings={[
          "Buildings",
          "Facility manager",
          "Profile type",
          "Created time",
        ]}
        tableJson={buildingTableJson}
      />

      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  buildingList: state.buildingList.buildingList,
});
export default connect(mapStateToProps, {})(Buildings);
