import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
const AdminConsoleDataTable = (props) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    setTimeout(function () {
      setIsDataLoaded(true);
    }, 20);
  });
  const fadeInProps = {
    opacity: isDataLoaded ? 1 : 0,
  };
    function getActions() {
        return {
            name: "",
            cell: (row) => (
                <div className="d-flex gap-1">
                    <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() => props.handleClone(row)}
                        style={{cursor: 'pointer'}}
                    >
                        Clone
                    </button>
                    <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() => props.handleEdit(row)}
                        style={{marginRight: '8px', cursor: 'pointer'}}
                    >
                        Edit
                    </button>
                </div>
            ),
        };
    }

    const sortNumbersWithUndefinedValues = (rowA, rowB) => {
        const a = rowA.column3data;
        const b = rowB.column3data;

        if (a === undefined && b === undefined) return 0;
        if (a === undefined) return 1;
        if (b === undefined) return -1;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    const columns =
    props.tableHeadings.length === 5
      ? [
          {
            name: props.tableHeadings[0],
            cell: (row) => (
              <>
                {row.image}
                {row.imageText}
              </>
            ),
            width: "320px",
          },
          {
            name: props.tableHeadings[1],
            selector: (row) => <>{row.column1data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[2],
            selector: (row) => <>{row.column2data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[3],
            selector: (row) => <>{row.column3data}</>,
            sortable: true,
          },
          {
            name: props.tableHeadings[4],
            selector: (row) => <>{row.column4data}</>,
            sortable: true,
          },
        ]
      : [
          {
            name: props.tableHeadings[0],
            sortable: true,
            selector: (row) => row.name,
            id: "name",
            cell: (row) => (
              <>
                {row.image}
                {row.imageText}
              </>
            ),
          },
          {
            name: props.tableHeadings[1],
            selector: (row) => row.column1data,
            sortable: true,
          },
          {
            name: props.tableHeadings[2],
            selector: (row) => row.column2data,
            sortable: true,
          },
          {
            name: props.tableHeadings[3],
            selector: (row) => row.column3data,
            sortFunction: sortNumbersWithUndefinedValues,
            sortable: true,
          }
        ];
    if (props.addActions) {
        columns.push(getActions());
    }
    return (
        <div className="test-animation" style={fadeInProps}>
            <div className="user-content-wrapper">
                <DataTable
                columns={columns}
                data={props.tableJson}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 30, 40, 50, 100]}
                defaultSortAsc
                defaultSortFieldId="name"
                pagination
                keyField="id.id"
                noDataComponent={"No Records Found!!"}
            /></div>
    </div>
  );
};
export default AdminConsoleDataTable;
