// eslint-disable-next-line react-hooks/exhaustive-deps
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import CircularDonutChart from "../../../component/CircularDonutChart";
import PerformanceMetrics from "../../../component/PerformanceMetrics";
import { howDoingData } from "./SnapshotData";
import fetchData from "../../../axios/fetchData";
import { telemetry } from "../../../axios/serverUrl";
import React, { useEffect, useState } from "react";
import { activeCoil } from "../../../redux/actions/coilAction";
import CustomLoader from "../../../component/Loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import SmartCoilImage from "../../../component/SmartCoilImage/SmartCoilImage";
import {
  getDescriptionForState,
  getFullLabel,
  getLabel,
  getLabelForState,
  getSmartCoilColor,
  OPTIMIZING_STATE,
  RUNNING_STATE
} from "../../../utils/device";
import {getSelectedSharedAttributes} from "../../../axios/deviceApi";
import {usePageTracking} from "../../../analytics/analytics";

function getSelectedDeviceTitle(recentSnapList) {
  const selectedDevice = recentSnapList?.filter((item) => item.active === true)[0];
  return (<span className="breadcrumbs-subtitle" title={getFullLabel(selectedDevice)}>{getLabel(selectedDevice)}</span>);
}

function getStatus(smartCoilState) {
  if (smartCoilState == null || smartCoilState === RUNNING_STATE) {
    return <></>;
  }
  return <div>
    <h4>
      Status - {getLabelForState(smartCoilState)}
      <span
          className="info-icon ml-2"
          type="button"
          data-tooltip-id={"smart-coil-state-tooltip"}
      >
      <span className="p-button-icon pi pi-info-circle"></span>
    </span>
    </h4>
    <ReactTooltip
        id="smart-coil-state-tooltip"
        place="right-start"
        style={{
          zIndex: 9999,
          width: "200px",
          background: "black",
          marginTop: "-10px",
          marginLeft: "10px",
        }}
        content={getDescriptionForState(smartCoilState)}
    />
  </div>
;
}

const Snapshot = ({
  activeList,
      activeSnapList,
      recentList,
      buildingList,
      recentSnapList,
      relationList,
}) => {
  usePageTracking();
  const navigator = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [recentCoilActiveList, setRecentCoilActiveList] = useState([]);
  const [activeBuildingName, setActiveBuildingName] = useState("");
  if (!JSON.parse(localStorage.getItem("userDetail"))?.email) {
    navigator("/login");
  }
  useEffect(() => {
    if (activeSnapList && activeSnapList.length > 0) {
      getFetchChartData();
      // getFetchRelation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSnapList]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getFetchChartData = async () => {
    setLoading(true);
    const selectedDevice = activeSnapList.filter((item) => item?.active)[0];
    const telemetryData = await fetchData(
      "GET",
      telemetry +
        "/" +
        selectedDevice?.id.entityType +
        "/" +
        selectedDevice?.id.id +
        "/values/timeseries?keys=clean_serv,co2_sav,totcostsav_ener,totsav_ener" +
        "&limit=100&agg=MAX&orderBy=ASC",
      { "X-Authorization": "Bearer " + localStorage.getItem("token") },
      {}
    );

    const eventsSince = new Date();
    eventsSince.setFullYear(eventsSince.getFullYear() - 3)
    const events = await fetchData(
        "GET",
        telemetry +
        "/" +
        selectedDevice?.id.entityType +
        "/" +
        selectedDevice?.id.id +
        "/values/timeseries?keys=ahu_event" +
        "&startTs=" + eventsSince.getTime() +
        "&endTs=" + new Date().getTime() +
        "&limit=10&orderBy=DESC",
        { "X-Authorization": "Bearer " + localStorage.getItem("token") },
        {}
    ).then((response) => {
      return response.ahu_event
    });

    const attributes = await getSelectedSharedAttributes(selectedDevice.id);

    if (telemetryData) {
      const monthsCount = (timestamp) => {
        if (timestamp) {
          const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          const firstDate = new Date(timestamp);
          const secondDate = new Date();
          const diffDays = Math.round(
            Math.abs((firstDate - secondDate) / oneDay)
          );
          var months = Math.floor(diffDays / 30);
          return months;
        }
      };
      const activeItem = activeSnapList.find((item) => item?.active);
      const co2SavValue = telemetryData?.co2_sav ? telemetryData.co2_sav[0].value : 0;
      const energySavingValue = telemetryData?.totsav_ener
        ? telemetryData.totsav_ener[0].value
        : 0;
      const costSavingValue = telemetryData?.totcostsav_ener
        ? telemetryData.totcostsav_ener[0].value
        : 0;
      const monthsToNextCleaning = telemetryData?.clean_serv
        ? telemetryData.clean_serv[0].value
        : 0;
      const timestampOfLastCleaning = events?.find(obj => obj.value === "Coil_Cleaned")?.ts;

      const smartCoilState = attributes['smartcoil_state'];
      const foulingLevel = attributes['flvl'];
      let newSmartCoilData =
        {
          id: activeItem?.id.id,
          name: activeItem?.label || activeItem?.name,
          smartcoil_state: smartCoilState,
          events: events,
        };

      if (smartCoilState === OPTIMIZING_STATE) {
        newSmartCoilData = {
          ...newSmartCoilData,
          months_since_last_cleaning: monthsCount(timestampOfLastCleaning),
          fouling_level: 0,
        }
      }

      if (smartCoilState === RUNNING_STATE) {
        newSmartCoilData =
            {
              ...newSmartCoilData,
              months_since_last_cleaning: monthsCount(timestampOfLastCleaning),
              fouling_level: Math.round(foulingLevel),
              carbon_offset: co2SavValue,
              carbon_offset_per: 5,
              grade: "--",
              energy_saving: energySavingValue,
              energy_saving_per: 4,
              cost_saving: costSavingValue,
              cost_saving_per: 8,
              months_to_next_cleaning: Math.round(monthsToNextCleaning),
            };
      }
      setLoading(false);
      setRecentCoilActiveList(newSmartCoilData);

      const relatedBuildingId = relationList.filter(
        (coil) => coil.CoilId === newSmartCoilData.id
      )[0]?.BuildingId;
      setActiveBuildingName(
        buildingList.filter(
          (building) => building.id.id === relatedBuildingId
        )[0]?.name
      );
    } else if (telemetryData?.errorCode === 11) {
      navigator("/login");
      localStorage.clear();
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="layout-box ">
      <div style={{ overflow: "auto" }}>
        <div style={{ flex: "0 0 auto" }} className="">
          <h2 className="snapshot-title fouling-metrics-title">Snapshot</h2>
          <div className="mb-4 snapshot-bradcrumb">
            <span className="breadcrumbs-title">
              {activeBuildingName}
              {activeBuildingName !== "" && "  >  "}
            </span>
            {getSelectedDeviceTitle(recentSnapList)}
          </div>
          <div className="grid-base grid3">
            <div className="c2">
              <h3>At-A-Glance</h3>
              <div className="grid-base grid2">
                <div className="d-flex right-border flex-column align-items-center justify-content-center">
                  <h2>Coil Fouling Level</h2>
                  <div>
                    <CircularDonutChart
                        selectedValue={recentCoilActiveList?.fouling_level}
                        maxValue={10}
                        textColor="#000"
                        valueFontSize={72}
                        radius={90}
                        strokeWidth={20}
                        activeStrokeColor="#0f4fff"
                        inactiveStrokeColor="#FFF"
                        backgroundColor="#F4F4F4"
                        withGradient
                        anticlockwise={false}
                    />
                  </div>
                  <h3 className="mt-4 mb-3">Months to Next Cleaning</h3>
                  <div className="big-number mt-0">
                    {recentCoilActiveList?.months_to_next_cleaning ? Math.round(recentCoilActiveList?.months_to_next_cleaning) : ""}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-start">
                  {getStatus(recentCoilActiveList?.smartcoil_state)}
                  <div className="gateway justify-content-center align-items-center">
                    <SmartCoilImage
                      color={getSmartCoilColor(recentCoilActiveList?.fouling_level)}
                    />
                  </div>
                  <hr />
                  <h4>
                    {recentCoilActiveList?.events
                      ? "Event Tracker"
                      : ""}
                  </h4>
                  <div className="grid2 event-tracker">
                    <table>
                      <tbody>
                        {recentCoilActiveList?.events?.map((item, index) => {
                          if (index <= 1 && item?.value !== null) {
                            return (
                              <tr key={item?.value + index}>
                                <td style={{minWidth: '100px'}}>{item?.value?.replaceAll('_', ' ')}</td>
                                <td>
                                  {moment(new Date(parseInt(item?.ts))).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </td>
                                <td>{moment(item?.ts).format("hh:mm A")}</td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <PerformanceMetrics coilData={recentCoilActiveList} />
            <div className="c3 grid-green">
              <h3>How are you doing?</h3>
              <div className="fancy-boxes ">
                {howDoingData &&
                  howDoingData?.map((item, index) => (
                    <div key={item?.id}>
                      <div className="d-flex justify-content-between">
                        <span className="colored">{item?.title}</span>
                      </div>
                      <div className="text-900 font-normal text-4xl flex justify-content-center" style={{minHeight: '47.25px'}}>
                        {
                          recentCoilActiveList?.smartcoil_state !== RUNNING_STATE
                              ? <></>
                              :
                          index === 0
                          ? recentCoilActiveList?.energy_saving == null
                            ? "N/A"
                            : parseInt(
                                recentCoilActiveList?.energy_saving
                              )?.toLocaleString("en-US")
                          : index === 1
                          ? recentCoilActiveList?.cost_saving == null
                            ? "N/A"
                            : "$" +
                              parseInt(
                                recentCoilActiveList?.cost_saving
                              )?.toLocaleString("en-US")
                          : recentCoilActiveList?.carbon_offset == null
                          ? "N/A"
                          : (Math.round(Number(
                              recentCoilActiveList?.carbon_offset
                            ) * 10) / 10)?.toLocaleString("en-US")}
                      </div>
                      <div className="d-flex justify-content-center savings-text">
                        {item.valueSymbol}
                      </div>
                      <span
                        className="info-icon mb-1 mr-3"
                        type="button"
                        data-tooltip-id={"my-tooltip-" + index}
                      >
                        <span className="p-button-icon pi pi-info-circle"></span>
                      </span>
                      <ReactTooltip
                        id={"my-tooltip-" + index}
                        place="left"
                        style={{
                          zIndex: 9999,
                          width: "200px",
                          background: "black",
                        }}
                        content={item?.tooltip}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomLoader
        idLoading={isLoading}
        handleLoading={() => setLoading(!isLoading)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  coilList: state.coilList.coilList,
  activeList: state.coilList.activeList,
  activeSnapList: state.coilList.activeSnapList,
  recentList: state.coilList.recentList,
  buildingList: state.buildingList.buildingList,
  recentSnapList: state.coilList.recentSnapList,
  relationList: state.buildingList.relationList,
});
export default connect(mapStateToProps, { activeCoil })(Snapshot);
