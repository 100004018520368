import {isEmpty} from "./stringUtils";

export const BASELINING_STATE = 1;
export const OPTIMIZING_STATE = 2;
export const RUNNING_STATE = 3;

export const getLabelForState = (smartCoilState) => {
    switch (smartCoilState) {
        case 0: return 'Provisioned';
        case 1: return 'Baselining';
        case 2: return 'Optimizing';
        case 3: return 'Monitoring';
        default: return '';
    }
}

export const getDescriptionForState = (smartCoilState) => {
    switch (smartCoilState) {
        case 0: return 'The SmartCoil was provisioned and is waiting for installation.';
        case 1: return 'The SmartCoil is currently collecting telemetries to create a baseline for energy consumption and performance of the coils and the air handling unit. The SmartCoil Gateway will continue to baseline until the cleaning of the coils has been completed.';
        case 2: return 'The SmartCoil is currently evaluating the improvements in performance and energy efficiency while calculating the fouling rates and optimal cleaning schedule. This process could take up to 2 weeks to optimize after which the reports will be generated, and data will be available to view.';
        case 3: return 'Running';
        default: return 'Unknown';
    }
}

export const getSmartCoilColor = (foulingLevel) => {
    return foulingLevel == null
        ? "#377abf"
        : foulingLevel < 0
            ? "#377abf"
            : foulingLevel < 50
                ? "#00ab4e"
                : foulingLevel < 70
                    ? "#eee81e"
                    : foulingLevel < 90
                        ? "#fba930"
                        : "#ed2326"
}

export const getLabel = (device) => {
    if (!device) {
        return "";
    }
    const text = isEmpty(device.label) ? device.name : device.label
    if (text?.length > 15) {
        return text.substring(0, 15) + "..."
    }
    return text;
}

export const getFullLabel = (device) => {
    if (!device) {
        return "";
    }
    return isEmpty(device.label) ? device.name : device.name + ': ' + device.label
}
